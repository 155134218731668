import gsap from 'gsap'
import {ScrollSmoother} from 'gsap/ScrollSmoother'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollEvent} from '../types'
import {$PAGE_SCROLLER, $PAGE_WRAPPER} from '../util'

gsap.registerPlugin(ScrollSmoother, ScrollToPlugin, ScrollTrigger)

const scroller = ScrollSmoother.create({
	wrapper: $PAGE_WRAPPER.el,
	content: $PAGE_SCROLLER.el,
	effects: true,
	smooth: 1,
	smoothTouch: false,
	normalizeScroll: {
		type: 'touch',
	},
	ignoreMobileResize: true,
	onUpdate: (instance) => {
		window.dispatchEvent(
			new CustomEvent<ScrollEvent>('onScroll', {
				detail: {
					top: instance.scrollTop(),
				},
			}),
		)
	},
})

window.addEventListener('onResizeEnd', () => {
	scroller.refresh()
})

export default scroller
