import Swiper, {FreeMode} from 'swiper'
import {$, Component, ComponentFactory, isMD, Query} from '../util'

class SliderComponent extends Component {
	$container: Query

	swiper?: Swiper

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$container = $(this.el)

		if (isMD()) {
			this._createSlider()
		}
	}

	onResize() {
		if (isMD()) {
			this._createSlider()
		} else {
			this._destroySlider()
		}
	}

	onDestroy() {
		this._destroySlider()
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private _createSlider() {
		if (this.swiper) return

		this.$container.addClass('slider--active')

		this.swiper = new Swiper(this.el, {
			freeMode: true,
			modules: [FreeMode],
			slidesPerView: 'auto',
			spaceBetween: 60,
		})
	}

	private _destroySlider() {
		this.$container.removeClass('slider--active')

		this.swiper?.destroy()
		this.swiper = undefined
	}
}

export default new ComponentFactory({
	selector: '.slider',
	component: SliderComponent,
})
