import {ComponentEvent, ComponentFactoryOptions, ComponentConstructor, ScrollEvent} from '../types'
import {Component} from './_component'

const EVNTS: ComponentEvent[] = [
	{
		eventName: 'keydown',
		eventHost: document,
		method: 'onKeydown',
	},
	{
		eventName: 'onLoadEnd',
		eventHost: window,
		method: 'onLoadEnd',
	},
	{
		eventName: 'onLoadStart',
		eventHost: window,
		method: 'onLoadStart',
	},
	{
		eventName: 'onNavEnd',
		eventHost: window,
		method: 'onNavEnd',
	},
	{
		eventName: 'onNavStart',
		eventHost: window,
		method: 'onNavStart',
	},
	{
		eventName: 'onResize',
		eventHost: window,
		method: 'onResize',
	},
	{
		eventName: 'onResizeX',
		eventHost: window,
		method: 'onResizeX',
	},
	{
		eventName: 'onResizeY',
		eventHost: window,
		method: 'onResizeY',
	},
	{
		eventName: 'onResizeEnd',
		eventHost: window,
		method: 'onResizeEnd',
	},
	{
		eventName: 'onUpdate',
		eventHost: window,
		method: 'onUpdate',
	},
]

/**
 * The ComponentFactory instantiates a new instance of a component class for each Element that matches the selector.
 */
export class ComponentFactory {
	private _components: Component<HTMLElement>[] = []

	private _constructorFn: ComponentConstructor

	private _selector: string

	constructor(options: ComponentFactoryOptions) {
		this._constructorFn = options.component
		this._selector = options.selector

		window.addEventListener('DOMContentLoaded', () => {
			this._update()
		})

		window.addEventListener('onUpdate', () => {
			this._update()
		})

		// Setup event listeners based on the prototype methods
		EVNTS.forEach((evnt) => {
			if (!(evnt.method in this._constructorFn.prototype)) return

			evnt.eventHost.addEventListener(evnt.eventName, (e) => {
				this._components.forEach((component) => {
					component[evnt.method](e)
				})
			})
		})
	}

	private _update() {
		// Destroy components whose element no longer exists
		this._components = this._components.filter((component) => {
			const isAlive = document.body.contains(component.el)

			if (!isAlive && component.onDestroy) {
				component.onDestroy()
			}

			return isAlive
		})

		// Create new components
		const els = document.querySelectorAll<HTMLElement>(`${this._selector}:not([component*="${this._constructorFn.name}"])`)

		els.forEach((el) => {
			if (el.hasAttribute('component')) {
				el.setAttribute('component', `${el.getAttribute('component')} ${this._constructorFn.name}`)
			} else {
				el.setAttribute('component', this._constructorFn.name)
			}

			const component = new this._constructorFn(el)

			if (component.onInit) {
				component.onInit()
			}

			if (component.onScroll) {
				window.addEventListener('onScroll', ((e: CustomEvent<ScrollEvent>) => {
					component.onScroll!(e)
				}) as EventListener)
			}

			this._components.push(component)
		})
	}
}
