import {$} from './_query'

export const $HTML = $(document.documentElement)

export const $BODY = $(document.body)

export const $PAGE = $('#page')

export const $PAGE_HEADER = $('#page-header')

export const $PAGE_FOOTER = $('#page-footer')

export const $PAGE_WRAPPER = $('#page-wrapper')

export const $PAGE_SCROLLER = $('#page-scroller')

export const $PAGE_CONTENT = $('#page-content')

export const $PAGE_OVERLAYS = $('#page-overlays')

export const GTAG_ID = ''

export const RECAPTCHA_KEY = ''
