import {ScrollEvent} from '../types'

/**
 * The base component class.
 */
export class Component<T extends HTMLElement = HTMLElement> {
	/**
	 * The root element of the component.
	 */
	el: T

	constructor(el: T) {
		this.el = el
	}

	/**
	 * A callback method for the OnDestroy event.
	 * Invoked when a component class is destroyed.
	 */
	onDestroy?(): void

	/**
	 * A callback method for the OnInit event.
	 * Invoked when a component class is initialized.
	 */
	onInit?(): void

	/**
	 * A callback method for the OnKeydown event.
	 * Invoked when a key is pressed.
	 */
	onKeydown?(e: KeyboardEvent): void

	/**
	 * A callback method for the OnLoadStart event.
	 * Invoked when the app has finished loading data e.g. from an api.
	 */
	onLoadEnd?(e: Event): void

	/**
	 * A callback method for the OnLoadStart event.
	 * Invoked when the app is loading data e.g. from an api.
	 */
	onLoadStart?(e: Event): void

	/**
	 * A callback method for the OnNavEnd event.
	 * Invoked when the router has finished loading the new page.
	 */
	onNavEnd?(e: Event): void

	/**
	 * A callback method for the OnNavStart event.
	 * Invoked when the router has started loading the new page.
	 */
	onNavStart?(e: Event): void

	/**
	 * A callback method for the OnResize event.
	 * Invoked when the window is resized horizontally or vertically.
	 */
	onResize?(e: Event): void

	/**
	 * A callback method for the OnResizeX event.
	 * Invoked when the window is resized horizontally.
	 */
	onResizeX?(e: Event): void

	/**
	 * A callback method for the OnResizeY event.
	 * Invoked when the window is resized vertically.
	 */
	onResizeY?(e: Event): void

	/**
	 * A callback method for the onResizeEnd event.
	 * Invoked when the window has stopped being resized.
	 */
	onResizeEnd?(e: Event): void

	/**
	 * A callback method for the OnScroll event.
	 * Invoked when the scroll-smoother has been updated.
	 */
	onScroll?(e: CustomEvent<ScrollEvent>): void

	/**
	 * A callback method for the OnUpdate event.
	 * Invoked when the app has updated the DOM.
	 */
	onUpdate?(e: Event): void
}
