import gsap from 'gsap'

export class Query<T extends HTMLElement = HTMLElement> extends Array<T> {
	constructor(selectors?: T | string | Array<T | string>) {
		super()

		if (!selectors) {
			throw `selectors should be a string, HTMLElement or Array<string | HTMLElement>. received: ${typeof selectors}`
		}

		const nodes = this._selectAll<T>(selectors)

		this.push(...nodes)
	}

	// Computed Properties
	// ----------------------------------------

	get any() {
		return this.length > 0
	}

	get el() {
		return this.first
	}

	get first() {
		return this.any ? this[0] : undefined
	}

	get last() {
		return this.any ? this[this.length - 1] : undefined
	}

	// Static Methods
	// ----------------------------------------

	static stringToHTML = (str: string) => {
		const nodes = Array<HTMLElement>()
		const wrapper = document.createElement('div')
		wrapper.innerHTML = str

		Array.from(wrapper.children).forEach((el) => {
			nodes.push(el as HTMLElement)
		})

		return nodes
	}

	// Private Methods
	// ----------------------------------------

	_selectAll<T2 extends HTMLElement = HTMLElement>(selectors: T2 | string | Array<T2 | string>, root: Document | Element = document) {
		let nodes: Array<T2> = []

		if (Array.isArray(selectors)) {
			selectors.forEach((s) => {
				nodes = [...nodes, ...this._select<T2>(s, root)]
			})
		} else {
			nodes = [...this._select(selectors, root)]
		}

		return nodes
	}

	_select<T2 extends HTMLElement = HTMLElement>(selector: T2 | string, root: Document | Element = document) {
		if (typeof selector === 'string') {
			return [...root.querySelectorAll<T2>(selector)]
		}

		return [selector]
	}

	// Public Methods
	// ----------------------------------------

	closest<T2 extends HTMLElement = HTMLElement>(selectors: string) {
		const nodes: T2[] = []

		this.forEach((el) => {
			const closest = el.closest<T2>(selectors)

			if (closest) {
				nodes.push(closest)
			}
		})

		return new Query(nodes)
	}

	query<T2 extends HTMLElement = HTMLElement>(selector: T2 | string | Array<T2 | string>) {
		let nodes: Array<T2> = []

		this.forEach((el) => {
			nodes = [...nodes, ...this._selectAll<T2>(selector, el)]
		})

		return new Query(nodes)
	}

	contains(node: Element) {
		const index = this.findIndex((el) => el.contains(node))
		return index > -1
	}

	hasClass(token: string) {
		const index = this.findIndex((el) => el.classList.contains(token))
		return index > -1
	}

	addClass(token: string) {
		this.forEach((el) => {
			el.classList.add(token)
		})
		return this
	}

	removeClass(token: string) {
		this.forEach((el) => {
			el.classList.remove(token)
		})
		return this
	}

	toggleClass(token: string) {
		this.forEach((el) => {
			el.classList.toggle(token)
		})
		return this
	}

	css(styles: Record<string, string>) {
		this.forEach((el) => {
			Object.entries(styles).forEach(([key, value]) => {
				el.style.setProperty(key, value)
			})
		})
		return this
	}

	hasAttr(qualifiedName: string) {
		return this.first?.hasAttribute(qualifiedName)
	}

	hasData(qualifiedName: string) {
		return this.hasAttr(`data-${qualifiedName}`)
	}

	getAttr(qualifiedName: string) {
		return this.first?.getAttribute(qualifiedName)
	}

	getData(qualifiedName: string) {
		return this.getAttr(`data-${qualifiedName}`)
	}

	setAttr(qualifiedName: string, value: string) {
		this.forEach((el) => {
			el.setAttribute(qualifiedName, value)
		})
		return this
	}

	setData(qualifiedName: string, value: string) {
		return this.setAttr(`data-${qualifiedName}`, value)
	}

	removeAttr(qualifiedName: string) {
		this.forEach((el) => {
			el.removeAttribute(qualifiedName)
		})
		return this
	}

	removeData(qualifiedName: string) {
		return this.removeAttr(`data-${qualifiedName}`)
	}

	getStyle(property: string) {
		return this.first ? getComputedStyle(this.first).getPropertyValue(property) : null
	}

	setStyle(property: string, value: string) {
		this.forEach((el) => {
			el.style.setProperty(property, value)
		})
		return this
	}

	removeStyle(property: string) {
		this.forEach((el) => {
			el.style.removeProperty(property)
		})
		return this
	}

	on(e: string | string[], fn: (e: Event, el: HTMLElement) => any, options: boolean | AddEventListenerOptions = false) {
		let evnts: string[] = []

		if (typeof e === 'string') {
			evnts.push(e)
		}

		if (Array.isArray(e)) {
			evnts = e
		}

		this.forEach((el) => {
			evnts.forEach((evnt) => {
				el.addEventListener(evnt, (e) => fn.call(el, e, el), options)
			})
		})

		return this
	}

	emit<E = undefined>(name: string, data?: E) {
		this.forEach((el) => {
			const evnt = new CustomEvent<E>(name, {
				bubbles: true,
				detail: data,
			})

			el.dispatchEvent(evnt)
		})
		return this
	}

	getText() {
		return this.first?.innerText
	}

	setText(text: string) {
		this.forEach((el) => {
			el.innerText = text
		})
		return this
	}

	empty() {
		this.forEach((el) => {
			while (el.firstChild) {
				el.removeChild(el.firstChild)
			}
		})
		return this
	}

	prepend(position: 'beforebegin' | 'afterbegin' = 'afterbegin', ...elements: HTMLElement[]) {
		const nodes: HTMLElement[] = []

		this.forEach((el) => {
			elements.forEach((element) => {
				const node = el.insertAdjacentElement(position, element) as HTMLElement
				nodes.push(node)
			})
		})

		return new Query(...nodes)
	}

	prependHTML(position: 'beforebegin' | 'afterbegin' = 'afterbegin', str: string) {
		const elements = Query.stringToHTML(str)
		return this.prepend(position, ...elements)
	}

	append(position: 'beforeend' | 'afterend' = 'beforeend', ...elements: HTMLElement[]) {
		const nodes: HTMLElement[] = []

		this.forEach((el) => {
			elements.forEach((element) => {
				const node = el.insertAdjacentElement(position, element) as HTMLElement
				nodes.push(node)
			})
		})

		return new Query(...nodes)
	}

	appendHTML(position: 'beforeend' | 'afterend' = 'beforeend', str: string) {
		const elements = Query.stringToHTML(str)
		return this.append(position, ...elements)
	}

	clone() {
		const nodes: HTMLElement[] = []

		this.forEach((el) => {
			const node = el.cloneNode(true) as HTMLElement
			nodes.push(node)
		})

		return new Query(...nodes)
	}

	remove() {
		this.forEach((el) => {
			el.remove()
		})
	}

	rect() {
		return this.el?.getBoundingClientRect()
	}

	// Animation
	// ----------------------------------------

	async fadeIn() {
		const tl = gsap.timeline({paused: true})
		const els = [...this].filter((el) => getComputedStyle(el).getPropertyValue('display') === 'none')

		if (!els.length) return this

		tl.set(els, {
			display: 'block',
			overflow: 'hidden',
			height: 'auto',
			autoAlpha: 0,
		})

		tl.from(els, {
			duration: 0.2,
			clearProps: 'height',
			height: 0,
		})

		tl.to(els, {
			duration: 0.2,
			autoAlpha: 1,
		})

		await tl.play()
		return this
	}

	async fadeOut() {
		const tl = gsap.timeline({paused: true})
		const els = [...this].filter((el) => getComputedStyle(el).getPropertyValue('display') !== 'none')

		if (!els.length) return this

		tl.set(els, {
			overflow: 'hidden',
		})

		tl.to(els, {
			duration: 0.2,
			opacity: 0,
		})

		tl.add(() => {
			els.forEach((el) => {
				el.removeAttribute('style')
				el.style.display = 'none'
			})
		})

		await tl.play()
		return this
	}
}

export function $<T extends HTMLElement = HTMLElement>(selectors: T | string | Array<T | string>) {
	return new Query<T>(selectors)
}
